import React, { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";
//AOS
import AOS from "aos";
import "aos/dist/aos.css";
export default function Wholesaler() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });

  return (
    <div>
      <Helmet>
        <title>
          Wholesaler | Ease Optical Distributor Trading Digitally with OIC
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content=" Wholesaler | Ease Optical Distributor Trading Digitally with OIC"
        />
        <meta
          name="description"
          content="Direct point of contact for Prospective buyers. OIC eases trading with the right retailers in the optical industry for better business vision."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="Direct point of contact for Prospective buyers. OIC eases trading with the right retailers in the optical industry for better business vision."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-banner.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  <span>DIRECT POINT</span> OF CONTACT FOR
                  <span> PROSPECTIVE BUYERS</span>
                </h1>
                <a href="#" className="buttonStyleWhite hvr-forward">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container">
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Distributor/Wholesaler
                  </span>
                </h2>
                <p>
                  The distributor will become the manufacturer's direct factor
                  of touch for people searching for products/services. A
                  distributor is the handiest one who distributes items all
                  through a selected region. We've got a market and license this
                  system to govern the retailers for the distributors.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglassesWhS"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-01.svg"></img>
                      <p className="transitionWhSText">
                        {/* <strong>Inventory Management</strong> */}
                      </p>
                      <br></br>
                    </div>
                    <br />
                    <p className="featureIconDesc featureMarginTextCorrection">
                      The distributor can manage their inventory like adding
                      inventory from the library, modify inventory or upload
                      their own inventory. Overall control of inventory is
                      handled by distributors.{" "}
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-01.svg"></img>
                      <p className="transitionWhSText transitionDirection">
                        {/* <strong>Manage Order</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      The distributor is the only person authorized to manage
                      their orders and allows retailers to place orders based on
                      the same.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/dashboard-screen.png"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Dashboard</h2>
                <p>
                  The platform embarks on inventory and uploads its inventory
                  records from the manufacturers or the distributor. OiC has
                  1066 specific products and 84881 total inventories are
                  available. Manage incoming orders.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Start Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/dashboard-screen.png"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container-fluid">
            <div className="row " data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-6 aligner">
                <div className="container">
                  <div className="row padding60">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <h2 className="realistic-marker-highlight">
                        Inventory Library
                      </h2>
                      <p>
                        We've produced 15,000+ inventories of well-known
                        manufacturers inside the database for the vendors' easy
                        use and inventories are to be had in metadata. Stock
                        Library, assists with preserving far from giant bugs
                        spent on inventories.
                      </p>
                      <a href="#" className="buttonStyle hvr-forward">
                        Explore Now
                      </a>
                      <br></br> <br></br>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-6 aligner text-right imgWidth">
                <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-glass.png"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Update Inventory</h2>
                <p>
                  The Distributor can also add inventory from the Inventory
                  Library and IMPORT FROM EXCEL. Distributors can add their
                  inventory details and retailers have got admission to all the
                  inventories and might place the order right away.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Add Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row aligner" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Add Retailer</h2>
                <p>
                  Only the wholesaler may introduce the retailer and that helps
                  retailers to look at the stock of distributors within the
                  marketplace.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-02.png"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-03.jpg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Incoming Orders</h2>
                <p>
                  The brand / distributor has an Incoming Order feature. The
                  objective of the Incoming Order lists out pending, usual and
                  denied. The distributor can take action to just accept the
                  order or deny the order. As soon as the distributor accepts
                  the order, the precise product could be ordered efficiently.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-03.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <Testimonail />
      </Layout>
    </div>
  );
}
